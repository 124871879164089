@import-normalize;

/* Add fonts. */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Roboto_Slab/RobotoSlab-Medium.ttf')
    format('truetype');
  font-display: swap;
}
